import {SET_HOME_INFO} from "../ActionTypes";

const initialState = {
  homeInfo: null
}

const HomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOME_INFO:
      return {...state, homeInfo: action.payload }
    default:
      return state
  }
}

export default HomeReducer
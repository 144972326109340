import React from "react";

function Resume(props) {
  const {date, title, company, description, company_link, location, credential_link} = props.resumeData;
  return (
    <div className="mi-resume mt-30">
      <div className="mi-resume-summary">
        <h6 className="mi-resume-year">{date}</h6>
        <h6 className="mi-resume-year">{location}</h6>
      </div>
      <div className="mi-resume-details">
        <h5>{title}</h5>
        <h6 className="mi-resume-company">
          {
            company_link
              ? <a href={company_link} target={'_blank'} rel="noopener noreferrer" style={{color:'white'}}>{company}</a>
              : company
          }
          {
            credential_link && <a href={credential_link} target={'_blank'} rel="noopener noreferrer" style={{color:'white'}}>, Credential link</a>
          }
        </h6>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default Resume;

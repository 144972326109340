import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import FsLightbox from "fslightbox-react";

import { myStorage } from "../firebase/firebase";
const fixedImageUrl = "/images/portfolio-image-1.jpg";
const fixedLargeImageUrl = ["/images/portfolio-image-1-lg.jpg"];

function Portfolio(props) {
  const [toggler, setToggler] = useState(false);
  const [imageUrls, setImagesUrls] = useState(null);
  const [cover, setCover] = useState(null);
  const {title, description, url, imageUrl = fixedImageUrl, largeImageUrl = fixedLargeImageUrl, storage_folder} = props.content;

  const handleToggler = (value) => {
    setToggler(value);
  };

  useEffect( () => {
    if(storage_folder){
      async function fetchDownloadUrls() {
        // Create a Reference to the folder:
        const storageRef = myStorage.ref("images/portfolio/"+ storage_folder);
        if(storageRef){
          const resultList = await storageRef.listAll();
          if(resultList && resultList.items && resultList.items.length > 0){
            const { items } = resultList
            const imageUrlsFetched = [];
            for(let i = 0; i < items.length; i++){
              const item = items[i];
              const downloadUrl = await item.getDownloadURL();
              if(item.name === '1.webp'){
                setCover(downloadUrl);
              }
              if(downloadUrl){
                imageUrlsFetched.push(downloadUrl);
              }
            }
            setImagesUrls(imageUrlsFetched);
          }
        }
      }
      fetchDownloadUrls()
        .catch(error => {
          console.log('Error getting download urls', error)
        })
    }
  }, [storage_folder]);

  return (
    <div className={cover ? "mi-portfolio mi-portfolio-visible" : ''}>
      <div className="mi-portfolio-image">
        <img src={cover ? cover : imageUrl} alt={title} />
        <ul>
          {(imageUrls && !largeImageUrl) ? null : <li>
            <button onClick={() => handleToggler(!toggler)}>
              <Icon.ZoomIn/>
            </button>
          </li>}
          { url ? <li>
            <a rel="noopener noreferrer" target="_blank" href={url}>
              <Icon.Link/>
            </a>
          </li> : null}
        </ul>
      </div>
      {!url ? <h5>{title}</h5> : <h5>
        <a rel="noopener noreferrer" target="_blank" href={url}>
          {title}
        </a>
      </h5>}
      {description ? <h6>{description}</h6> : null}
      {(imageUrls && !largeImageUrl) ? null : <FsLightbox
        toggler={toggler}
        sources={imageUrls ? imageUrls : [largeImageUrl]}
        key={title}
      />
      }
    </div>
  );
}

export default Portfolio;

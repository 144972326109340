import React from 'react';

const findMyCarPrivacyPolicy = () => {
  return (
    <div style={{padding:30}}>
      <strong>Política de privacidad</strong> <p>
      Osiel Lima Díaz construyó la aplicación Find Parked Car como
      una aplicación gratuita. Este SERVICIO es proporcionado por
      Osiel Lima Díaz sin costo y está destinado a ser utilizado como
      es.
    </p> <p>
      Esta página se utiliza para informar a los visitantes sobre mi
      políticas con la recopilación, uso y divulgación de información personal
      Información si alguien decidió utilizar mi Servicio.
    </p> <p>
      Si decide utilizar mi Servicio, entonces acepta
      la recopilación y uso de información en relación con este
      política. La información personal que recopilo es
      utilizado para proporcionar y mejorar el Servicio. No usaré ni compartiré su información con
      cualquier persona excepto como se describe en esta Política de privacidad.
    </p> <p>
      Los términos utilizados en esta Política de privacidad tienen los mismos significados
      como en nuestros Términos y Condiciones, a los que se puede acceder en
      Buscar automóvil estacionado a menos que se defina lo contrario en esta Política de privacidad.
    </p> <p><strong>Information Collection and Use</strong></p> <p>
      Para una mejor experiencia, al utilizar nuestro Servicio,
      puede requerir que nos proporcione ciertos datos personales
      información identificable. La información que
      Solicito se mantendrá en su dispositivo y no lo recopilaré de ninguna manera.
    </p> <p><strong>Dato de registro</strong></p> <p>
      Translation results
      Quiero informarte que siempre que tu
      usar mi Servicio, en caso de error en la aplicación
      Recopilo datos e información (a través de terceros
      productos) en su teléfono llamado Datos de registro. Estos datos de registro pueden
      incluir información como el Protocolo de Internet de su dispositivo
      ("IP"), nombre del dispositivo, versión del sistema operativo,
      configuración de la aplicación al utilizar mi Servicio,
      la hora y fecha de su uso del Servicio, y otros
      Estadísticas.
    </p> <p><strong>Cookies</strong></p> <p>
      Las cookies son archivos con una pequeña cantidad de datos que se
      de uso común como identificadores únicos anónimos. Estos son enviados
      a su navegador desde los sitios web que visita y
      almacenados en la memoria interna de su dispositivo.
    </p> <p>
      Este Servicio no utiliza estas "cookies" explícitamente. Sin embargo,
      la aplicación puede utilizar código de terceros y bibliotecas que utilizan
      “Cookies” para recopilar información y mejorar sus servicios.
      Tiene la opción de aceptar o rechazar estas cookies.
      y saber cuándo se envía una cookie a su dispositivo. Si tu
      opta por rechazar nuestras cookies, es posible que no pueda utilizar algunas
      partes de este Servicio.
    </p> <p><strong>Proveedores de servicio</strong></p> <p>
      Puedo emplear empresas de terceros y
      personas por las siguientes razones:
    </p> <ul><li>Para facilitar nuestro Servicio;</li> <li>Para proporcionar el Servicio en nuestro nombre;</li> <li>Para realizar servicios relacionados con el servicio; o</li> <li>Para ayudarnos a analizar cómo se utiliza nuestro Servicio.</li></ul> <p>
      Quiero informar a los usuarios de este Servicio.
      que estos terceros tienen acceso a su Personal
      Información. El motivo es realizar las tareas asignadas a
      ellos en nuestro nombre. Sin embargo, están obligados a no
      divulgar o utilizar la información para cualquier otro propósito.
    </p> <p><strong>Seguridad</strong></p> <p>
      Valoro su confianza al brindarnos su
      Información personal, por lo que nos esforzamos por utilizar comercialmente
      medios aceptables para protegerlo. Pero recuerda que ningún método
      de transmisión a través de Internet, o método de transmisión electrónica
      el almacenamiento es 100% seguro y confiable, y no puedo
      Garantizamos su absoluta seguridad.
    </p> <p><strong>Enlaces a otros sitios</strong></p> <p>
      Este Servicio puede contener enlaces a otros sitios. Si hace clic en
      un enlace de terceros, se le dirigirá a ese sitio. Nota
      que estos sitios externos no son operados por mí.
      Por lo tanto, le recomiendo encarecidamente que revise el
      Política de privacidad de estos sitios web. yo tengo
      no controla ni asume ninguna responsabilidad por el contenido,
      políticas de privacidad o prácticas de sitios de terceros o
      servicios.
    </p> <p><strong>Privacidad de los niños</strong></p> <p>
      Estos Servicios no se dirigen a nadie menor de 13 años.
      No recopilo personalmente a sabiendas
      información identificable de niños menores de 13 años. En el caso
      Descubro que un niño menor de 13 años ha proporcionado
      me con información personal, inmediatamente
      elimine esto de nuestros servidores. Si eres padre o tutor
      y sabe que su hijo nos ha proporcionado
      información personal, por favor contácteme para que
      Podré realizar las acciones necesarias.
    </p> <p><strong>Cambios a esta política de privacidad</strong></p> <p>
      Puedo actualizar nuestra Política de privacidad desde
      tiempo al tiempo. Por lo tanto, le recomendamos que revise esta página.
      periódicamente para cualquier cambio. Voy a
      notificarle de cualquier cambio publicando la nueva Política de privacidad en
      esta página.
    </p> <p>Esta política es efectiva a partir del 2020-11-21</p> <p><strong>Contáctenos</strong></p> <p>
      Si tiene alguna pregunta o sugerencia sobre mi
      Política de privacidad, no dude en contactarme en osielmesa@gmail.com.
    </p> <p>Esta página de política de privacidad se creó en <a href="https://privacypolicytemplate.net" target="_blank" rel="noopener noreferrer">privacypolicytemplate.net </a>y modificado/generado por <a href="https://app-privacy-policy-generator.nisrulz.com/" target="_blank" rel="noopener noreferrer">App Privacy Policy Generator</a></p>
    </div>
  );
}

export default findMyCarPrivacyPolicy;

import React, { useEffect } from "react";
import Particles from "react-particles-js";
import { useSelector, useDispatch } from "react-redux";

//Local
import { myFirestore } from "../firebase/firebase";
import Socialicons from "../components/Socialicons";
import Layout from "../components/Layout";
import { setSocialLinks } from "../redux/actions/SocialLinksActions";
import { setHomeInfo } from "../redux/actions/HomeActions";

function Home(){
  const dispatch = useDispatch()
  const { homeInfo }  = useSelector(state => state.home)
  const paramConfig = {
    particles: {
      number: {
        value: 160,
        density: {
          enable: false
        }
      },
      color: {
        value: "#ffffff"
      },
      opacity: {
        value: 0.1
      },
      size: {
        value: 5,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3
        }
      },
      line_linked: {
        enable: false
      },
      move: {
        random: true,
        speed: 1,
        direction: "top",
        out_mode: "out"
      }
    }
  };

  useEffect(() => {
    //Getting home collection data-----------------------------------------------------------------------------
    const socialLinksRef = myFirestore.collection('home').doc('social-links');
    socialLinksRef.get().then(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        dispatch(setSocialLinks(doc.data()))
      }
    })
      .catch(err => {
        console.log('Error getting document', err);
      });
    //----------------------------------------------------------------------------------------------------------

    //Getting home collection data--------------------------------------------------
    const homeRef = myFirestore.collection('home').doc('home');
    homeRef.get()
      .then(doc => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          dispatch(setHomeInfo(doc.data()))
        }
      })
      .catch(err => {
        console.log('Error getting document', err);
      });
    //------------------------------------------------------------------------------
  },[dispatch])

  return (
    <Layout>
      <div className="mi-home-area mi-padding-section">
        <Particles
          className="mi-home-particle"
          params={{
            "particles": {
              "number": {
                "value": 50
              },
              "size": {
                "value": 3
              }
            },
            "interactivity": {
              "events": {
                "onhover": {
                  "enable": true,
                  "mode": "repulse"
                }
              }
            }
          }} />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="mi-home-content">
                <h1>
                  Hi, I am <span className="color-theme">{homeInfo ? homeInfo.name : 'Osiel Lima'}</span>
                </h1>
                <p>{homeInfo ? homeInfo.about : 'Senior Frontend Developer'}</p>
                <Socialicons bordered />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Home;

import {createStore, combineReducers, applyMiddleware} from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension';

import HeaderReducer from "./reducers/HeaderReducer";
import SocialLinksReducer from "./reducers/SocialLinksReducer";
import HomeReducer from "./reducers/HomeReducer";


const rootReducer  = combineReducers({
  header: HeaderReducer,
  socialLinks: SocialLinksReducer,
  home: HomeReducer
})

const store = createStore(rootReducer,composeWithDevTools(applyMiddleware(thunkMiddleware)))
export default store

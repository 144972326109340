import {SET_HEADER_CV, SET_HEADER_AVATAR_URL} from "../ActionTypes";

const initialState = {
  avatarUrl:null,
  cv:null
}

const HeaderReducer = (state = initialState, action) =>{
  switch (action.type) {
    case SET_HEADER_AVATAR_URL:
      return {...state, avatarUrl: action.payload}
      case SET_HEADER_CV:
      return {...state, cv: action.payload}
    default:
      return state
  }
}

export default HeaderReducer
import React, { useState } from 'react';
import {useSelector} from "react-redux";
import {NavLink, Link} from 'react-router-dom';
import LineIcon from 'react-lineicons';

const Header = () => {
    const {cv, avatarUrl} = useSelector(state => state.header)
    const [navigationToggler, setNavigationToggler] = useState(false);

    const handleNavigationToggler = () =>{
        setNavigationToggler(!navigationToggler);
    }

    return (
        <nav className={navigationToggler ? "mi-header is-visible" : "mi-header"}>
            <button onClick={handleNavigationToggler} className="mi-header-toggler">
                {!navigationToggler ? <LineIcon name="menu" /> : <LineIcon name="close" />}
            </button>
            <div className="mi-header-inner">
                <div className="mi-header-image">
                    <Link to="/">
                        <img src={avatarUrl} alt="brandimage"/>
                    </Link>
                </div>

                <ul className="mi-header-menu">
                    <li><NavLink exact to="/"><span>Home</span></NavLink></li>
                    <li><NavLink to="/about"><span>About</span></NavLink></li>
                    <li><NavLink to="/resume"><span>Resume</span></NavLink></li>
                    <li><NavLink to="/portfolios"><span>Portfolios</span></NavLink></li>
                    {/*<li><NavLink to="/blogs"><span>Blogs</span></NavLink></li>*/}
                    <li><NavLink to="/contact"><span>Contact</span></NavLink></li>
                    {cv && <li> <a rel="noopener noreferrer" target="_blank" href={cv}><span>Curriculum Vitae</span></a></li>}
                </ul>
                <p className="mi-header-copyright">&copy; {new Date().getFullYear()} <b><a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/osiel/">Osiel Lima Díaz</a></b></p>
            </div>
        </nav>
    )
}

export default Header;

import firebase from 'firebase/app'
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDxltcGoR1ziWTlzK-l9IZTR5WhnP_UHJM",
  authDomain: "osiel-portfolio-648fe.firebaseapp.com",
  databaseURL: "https://osiel-portfolio-648fe.firebaseio.com",
  projectId: "osiel-portfolio-648fe",
  storageBucket: "osiel-portfolio-648fe.appspot.com",
  messagingSenderId: "638375741634",
  appId: "1:638375741634:web:4390aef1b847bafd9f135b"
};

const myFirebase = firebase.initializeApp(firebaseConfig);
const myFirestore = myFirebase.firestore();
const myStorage = myFirebase.storage();

export {myFirebase, myFirestore, myStorage}

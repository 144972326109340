import React, { useState, useEffect } from "react";
import TrackVisibility from "react-on-screen";

//Local
import { myFirestore } from "../firebase/firebase";
import Sectiontitle from "../components/Sectiontitle";
import Smalltitle from '../components/Smalltitle';
import Layout from "../components/Layout";
import Progress from "../components/Progress";
import Resume from "../components/Resume";

function Resumes(){
  const [workingExperience, setWorkingExperience] = useState([]);
  const [educationExperience, setEducationExperience] = useState([]);
  const [coursesExperience, setCoursesExperience] = useState([]);
  const [skills, setSkills] = useState([]);

  useEffect(() =>{
    getResumeData()
  }, [])

  const getResumeData = () => {
    //getting skills------------------------------------------------------------------------------
    const skillssRef = myFirestore.collection('resume_skills');
    skillssRef.get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents.');
          return;
        }
        const skills = []
        snapshot.forEach(doc => {
          skills.push({...doc.data(), id: doc.id})
        });
        skills.sort((a, b) => (a.priority > b.priority) ? 1 : -1)
        setSkills(skills)
      })
      .catch(err => {
        console.log('Error getting documents', err);
      });
    //-------------------------------------------------------------------------------------------
    //getting working experience------------------------------------------------------------------------------
    const workingExperience = myFirestore.collection('resume_working_experience');
    workingExperience.get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents.');
          return;
        }
        const works = []
        snapshot.forEach(doc => {
          works.push({...doc.data(), id: doc.id})
        });
        works.sort((a, b) => (a.priority > b.priority) ? 1 : -1)
        setWorkingExperience(works)
      })
      .catch(err => {
        console.log('Error getting documents', err);
      });
    //-------------------------------------------------------------------------------------------
    //getting working experience------------------------------------------------------------------------------
    const educationExperience = myFirestore.collection('resume_education_experience');
    educationExperience.get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents.');
          return;
        }
        const studies = []
        snapshot.forEach(doc => {
          studies.push({...doc.data(), id: doc.id})
        });
        studies.sort((a, b) => (a.priority > b.priority) ? 1 : -1)
        setEducationExperience(studies)
      })
      .catch(err => {
        console.log('Error getting documents', err);
      });
    //-------------------------------------------------------------------------------------------
    //getting courses experience------------------------------------------------------------------------------
    const coursesExperience = myFirestore.collection('resume_study');
    coursesExperience.get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents.');
          return;
        }
        const courses = []
        snapshot.forEach(doc => {
          courses.push({...doc.data(), id: doc.id})
        });
        courses.sort((a, b) => (a.priority > b.priority) ? 1 : -1)
        setCoursesExperience(courses)
      })
      .catch(err => {
        console.log('Error getting documents', err);
      });
    //-------------------------------------------------------------------------------------------
  }

  return (
    <Layout>
      <div className="mi-skills-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title="My Skills" />
          <div className="mi-skills">
            <div className="row mt-30-reverse">
              {skills && skills.map(skill => (
                <TrackVisibility once className="col-lg-6 mt-30" key={skill.title}>
                  <Progress title={skill.title} percentage={skill.percent} />
                </TrackVisibility>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="mi-resume-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Resume" />
          <Smalltitle title="Working Experience" icon="briefcase" />
          <div className="mi-resume-wrapper">
            {workingExperience.map(workingExp => (
              <Resume key={workingExp.id} resumeData={workingExp} />
            ))}
          </div>
          <div className="mt-30"/>
          <Smalltitle title="Courses, Licenses and Certifications" icon="book" />
          <div className="mi-resume-wrapper">
            {coursesExperience.map(courseExp => (
              <Resume key={courseExp.id} resumeData={courseExp}/>
            ))}
          </div>
          <div className="mt-30"/>
          <Smalltitle title="Educational Qualifications" icon="book" />
          <div className="mi-resume-wrapper">
            {educationExperience.map(educationExp => (
              <Resume key={educationExp.id} resumeData={educationExp}/>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Resumes;

import {SET_HEADER_AVATAR_URL, SET_HEADER_CV} from "../ActionTypes";

export const setHeaderAvatarUrl = (url) => ({
  type: SET_HEADER_AVATAR_URL,
  payload: url
})

export const setHeaderCV = (url) => ({
  type: SET_HEADER_CV,
  payload: url
})
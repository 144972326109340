import React, { useState, useEffect } from "react";

//Local
import { myFirestore } from "../firebase/firebase";
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import PortfoliosView from "../components/PortfoliosView";
import Smalltitle from "../components/Smalltitle";

function Portfolios() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getPortfolios()
  }, []);

  const getPortfolios = () => {
    //getting categories ------------------------------------------------------------------------------
    const categoriesRef = myFirestore.collection('project_categories')
    categoriesRef.get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents.');
          return;
        }
        const categories = []
        snapshot.forEach(doc => {
          categories.push({...doc.data(), id: doc.id})
        });
        categories.sort((a, b) => (a.priority > b.priority) ? 1 : -1)
        getProjects(categories)
      })
      .catch(err => {
        console.log('Error getting documents', err);
      });
    //-------------------------------------------------------------------------------------------
  }

  const getProjects = (categoriesParam) => {
    const categories = categoriesParam;
    //getting portfolios ------------------------------------------------------------------------------
    const portfolioExperience = myFirestore.collection('projects');
    portfolioExperience.get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents.');
          return;
        }
        snapshot.forEach(doc => {
          const categoryKey = doc.data().category
          categories.forEach(category => {
            if(category.id === categoryKey){
              if(!category.portfolios){
                category.portfolios = []
              }
              category.portfolios.push({...doc.data(), id: doc.id})
            }
          })
        });
        setCategories(categories)
      })
      .catch(err => {
        console.log('Error getting documents', err);
      });
    //-------------------------------------------------------------------------------------------
  }

  return (
    <Layout>
      <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title='Portfolios' />
          {categories && categories.map(category => {
            return (
              <div key={category.id}>
                <Smalltitle title={category.title} />
                <br/>
                <PortfoliosView portfolios={category.portfolios}/>
                <br/>
                <br/>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  );
}

export default Portfolios;

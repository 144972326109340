import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import FsLightbox from "fslightbox-react";
import * as Icon from "react-feather";
import { useSelector } from "react-redux";

//Local
import { myFirestore, myStorage } from "../firebase/firebase";
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import Testimonial from '../components/Testimonial';

function About(){
  const { homeInfo }  = useSelector(state => state.home)
  const [aboutInfo, setAboutInfo] = useState(null)
  const [aboutImage, setAboutImage] = useState(null)
  const [recommendations, setRecommendations] = useState(null)
  const [aboutImageLg, setAboutImageLg] = useState(null)
  const [recommendation, setRecommendation] = useState(null)
  const {cv}  = useSelector(state => state.header)

  const [toggler, setToggler] = useState(false);
  const [togglerRecomendation, setTogglerRecomendation] = useState(false);

  const sliderSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  };

  const handleToggler = (toggler) => {
    setToggler(toggler)
  }

  const handleTogglerRecommendation = (toggler) => {
    setTogglerRecomendation(toggler)
  }

  useEffect(() => {
    getAboutInfo()
  }, [])

  const getAboutInfo = () => {
    //Getting about collection data-----------------------------------------------------------------
    const aboutRef = myFirestore.collection('about').doc('aboutDoc');
    aboutRef.get()
      .then(doc => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          setAboutInfo(doc.data())
        }
      })
      .catch(err => {
        console.log('Error getting document', err);
      });
    //---------------------------------------------------------------------------------------------
    //Getting recommendations ------------------------------------------------------------------
    const recommendationsRef = myFirestore.collection('recommendations');
    recommendationsRef.get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents.');
          return;
        }
        const recommendations = []
        snapshot.forEach(doc => {
          recommendations.push(doc.data())
        });
        setRecommendations(recommendations)
      })
      .catch(err => {
        console.log('Error getting documents', err);
      });
    //-------------------------------------------------------------------------------------------
    //Getting about image 750x565---------------------------------------------------------
    myStorage.ref('/images/about/image750x565.jpg').getDownloadURL().then(url => {
      setAboutImage(url)
    }).catch(error => {
      console.log(error)
    })
    //-------------------------------------------------------------------------------------

    //Getting about image 1920x1293---------------------------------------------------------
    myStorage.ref('/images/about/image1920x1293.jpg').getDownloadURL().then(url => {
      setAboutImageLg(url)
    }).catch(error => {
      console.log(error)
    })
    myStorage.ref('/images/about/recommendation_letter.png').getDownloadURL().then(url => {
      setRecommendation(url)
    }).catch(error => {
      console.log(error)
    })
    //-------------------------------------------------------------------------------------
  }

  return (
    <Layout>
      <div className="mi-about-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title="About Me" />
          <div className="row">
            <div className="col-lg-6">
              <div className="mi-about-image">
                <img
                  src={aboutImage}
                  alt="about"
                  onClick={() => handleToggler(!toggler)}
                />
                <span className="mi-about-image-icon">
                  <Icon.ZoomIn />
                </span>
                { aboutImageLg &&
                <FsLightbox
                  toggler={toggler}
                  sources={[aboutImageLg]}
                  type='image'
                />}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mi-about-content">
                <h3>
                  I am <span className="color-theme">{homeInfo && homeInfo.name ? homeInfo.name : 'Osiel Lima Díaz'}</span>
                </h3>
                <p>
                  {aboutInfo && aboutInfo.about? aboutInfo.about : 'I am a frontend web developer. I can provide clean code and pixel perfect design.' }
                </p>
                <ul>
                  <li>
                    <b>Full Name</b> {aboutInfo && aboutInfo.fullname ? aboutInfo.fullname : 'Osiel Lima Díaz'}
                  </li>
                  <li>
                    <b>Age</b> {aboutInfo && aboutInfo.age ? aboutInfo.age : '31'} Years
                  </li>
                  <li>
                    <b>Nationality</b> {aboutInfo && aboutInfo.nationality ? aboutInfo.nationality : 'Cuban'}
                  </li>
                  <li>
                    <b>Location</b> {aboutInfo && aboutInfo.currentResidence ? aboutInfo.currentResidence : 'Belgrade, Serbia'}
                  </li>
                  <li>
                    <b>Languages</b> {aboutInfo && aboutInfo.languages ? aboutInfo.languages : 'Spanish, English'}
                  </li>
                 <li>
                    <b>Email</b> {aboutInfo && aboutInfo.email ? aboutInfo.email : 'osielmesa@gmail.com'}
                  </li>
                </ul>
                {cv && <a href={cv} className="mi-button" target='_blank' rel="noopener noreferrer">Download CV</a>}
              </div>
            </div>
          </div>
        </div>
      </div>
      {recommendations && recommendations.length > 0 &&
        <div className="mi-review-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Recommendations" />
          <div className="row justify-content-center">
            <div className="col-12">
              <Slider className="mi-testimonial-slider" {...sliderSettings}>
                {recommendations.map(recommendation =>(
                  <Testimonial key={recommendation.author} content={recommendation}/>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      }
      {recommendation &&
      <div className="mi-review-area mi-section mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Recommendation letters" />
          <div className="row">
            <div className="col-lg-6">
              <div className="mi-about-image">
                <img
                  src={recommendation}
                  alt="about"
                  onClick={() => handleTogglerRecommendation(!togglerRecomendation)}
                />
                <span className="mi-about-image-icon">
                  <Icon.ZoomIn />
                </span>
                { recommendation &&
                <FsLightbox
                  toggler={togglerRecomendation}
                  sources={[recommendation]}
                  type='image'
                />}
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </Layout>
  );
}

export default About;

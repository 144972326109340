import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import "./App.scss";
import Home from "./pages/Home";
import About from "./pages/About";
import Resumes from "./pages/Resumes";
import Portfolios from "./pages/Portfolios";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import BlogDetails from "./pages/BlogDetails";
import PrivacyPolicyFindMyCarES from './apps_info/findmycar/privacyPolicy/es/privacyPolicy'
import PrivacyPolicyFindMyCarEN from './apps_info/findmycar/privacyPolicy/en/privacyPolicy'
import { myStorage } from "./firebase/firebase";
import {setHeaderAvatarUrl, setHeaderCV} from "./redux/actions/HeaderActions";

const App = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    //Getting avatar image 300x300-------------------------------------------------
    myStorage.ref('/images/header/avatar300x300.jpg').getDownloadURL().then(url => {
      dispatch(setHeaderAvatarUrl(url))
    }).catch(error => {
      console.log(error)
    })
    //------------------------------------------------------------------------------

    //Getting CV pdf-------------------------------------------------------------------
    myStorage.ref('/cv/CV - Osiel Lima Diaz.pdf').getDownloadURL().then(url => {
      dispatch(setHeaderCV(url))
    }).catch(error => {
      console.log(error)
    })
    //---------------------------------------------------------------------------------

  },[dispatch] )

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" component={About} />
        <Route path="/resume" component={Resumes} />
        <Route path="/portfolios" component={Portfolios} />
        {/*<Route path="/blogs" exact component={Blogs} />*/}
        <Route path="/blogs/blog-details/:id/:title" component={BlogDetails} />
        <Route path="/contact" component={Contact} />
        <Route path="/apps/findmycar/privacypolicy/en" component={PrivacyPolicyFindMyCarEN} />
        <Route path="/apps/findmycar/privacypolicy/es" component={PrivacyPolicyFindMyCarES} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;

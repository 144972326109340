import React from "react";
import {useSelector} from "react-redux";
import LineIcon from 'react-lineicons';

function Socialicons(props){
  const {cv} = useSelector(state => state.header)
  let {socialLinks} = useSelector(state => state.socialLinks)

  if(!socialLinks){
    socialLinks = {}
  }

  return (
    <ul className={props.bordered ? 'mi-socialicons mi-socialicons-bordered' : 'mi-socialicons'}>
      {!socialLinks.linkedin ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={socialLinks.linkedin}>
          <LineIcon name="linkedin"/>
        </a>
      </li>}
      {!socialLinks.github ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={socialLinks.github}>
          <LineIcon name="github"/>
        </a>
      </li>}
      {!socialLinks.email ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={socialLinks.email}>
          <LineIcon name="envelope"/>
        </a>
      </li>}
      {!socialLinks.skype ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={socialLinks.skype}>
          <LineIcon name="skype"/>
        </a>
      </li>}
      {!cv ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={cv}>
          <LineIcon name="empty-file"/>
        </a>
      </li>}
    </ul>
  );
}

export default Socialicons;
